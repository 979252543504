import React from "react"
import Layout from "../components/layout"
import * as cn from "classnames";
import rodoFile from "../../static/RODO_w_Sempre_2021.pdf"

export default class FormForChild extends React.Component {
    state = {
        errors: false
    }

    sendForm(e) {
        e.preventDefault()
        if(this.agree.checked) {
            this.form.submit(e.target.elements)
            this.setState({errors: false})
        }
        else {
            this.setState({errors: true})
        }
    }

    render() {
        const s = this.state

        return (
            <Layout>
                <div className="main-image">
                    <img src="../kontakt.jpeg" alt="" />
                </div>
                <div className="content">
                    <div className="form-container">

                        <div className="page-title">
                            <h3>Formularz zgłoszenia dziecka</h3>
                        </div>
                        <form className="new-form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" name="myForm" id="myForm">
                            <input type="hidden" name="oid" value="00D09000001J9cZ" />
                            <input type="hidden" name="retURL" value="https://sempre.org.pl/formularz-zgloszenia-dziecka/" />
                            <div className="input">
                                <p>Imię:</p>
                                <input id="first_name" maxLength="40" name="first_name" size="20" type="text" required />
                            </div>
                            <div className="input">
                                <p>Nazwisko:</p>
                                <input id="last_name" maxLength="80" name="last_name" size="20" type="text" required />
                            </div>
                            <div className="input">
                                <p>Telefon:</p>
                                <input id="phone" maxLength="40" name="phone" size="20" type="text" required />
                            </div>
                            <div className="input">
                                <p>E-mail:</p>
                                <input id="email" maxLength="80" name="email" size="20" type="email" required />
                            </div>
                            <div className="input">
                                <p>Wiadomość:</p>
                                <textarea className="input-text" id="00N09000006ONr4" name="00N09000006ONr4" required />
                            </div>
                            <div className="input">
                                <p>Placówka:</p>
                                <select  id="00N09000006PMXe" name="00N09000006PMXe" title="Placówka">
                                    <option value="">--Brak--</option>
                                    <option value="Świetlica na Kowalskiej">Świetlica na Kowalskiej</option>
                                    <option value="Przystanek Sempre">Przystanek Sempre</option>
                                    <option value="Brak wyboru">Brak wyboru</option>
                                </select>
                            </div>
                            <div className="input">
                                <p>Imię dziecka:</p>
                                <input id="00N09000006ONqw" maxLength="50" name="00N09000006ONqw" size="20" type="text" required />
                            </div>
                            <div className="input">
                                <p>Nazwisko dziecka:</p>
                                <input id="00N09000006ONqy" maxLength="50" name="00N09000006ONqy" size="20" type="text" required />
                            </div>
                            <div className="input">
                                <p>Data urodzin dziecka:</p>
                                <input id="00N09000006ONqv" name="00N09000006ONqv"  size="20" type="text" required />
                            </div>
                            <div className="input">
                                <p>Płeć dziecka:</p>
                                <select  id="00N09000006ONqx" name="00N09000006ONqx" title="Płeć dziecka">
                                    <option value="">--Brak--</option>
                                    <option value="Male">Mężczyzna</option>
                                    <option value="Female">Kobieta</option>
                                    <option value="Non-Binary">Niebinarna</option>
                                    <option value="Prefer Not To Say">Woli nie mówić</option>
                                </select>
                            </div>
                            <div className={cn("input", {invalid: this.state.errors})}>
                                <label className="checkbox label-text input-checker">
                                    <input type="checkbox" className="input-text" id="00N09000006ONr2" name="00N09000006ONr2" value="1" ref={(el) => { this.agree = el }} />
                                    <span>Zgoda na przetwarzanie danych osobowych. <a href={rodoFile} target="_blank">Więcej informacji</a></span>
                                </label>
                            </div>
                            <input type="submit" name="submit" className="btn btn-yellow"/>
                        </form>
                    </div>
                </div>
            </Layout>
        )
    }
}
